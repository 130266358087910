import React, { useState, useCallback } from "react";

import DSDialog from "../system-design/DSDialog";
import DSDialogTitle from "../system-design/DSDialogTitle";
import DSDialogContent from "../system-design/DSDialogContent";
import DSSecondaryButton from "../system-design/DSSecondaryButton";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import DSTextField from "../system-design/DSTextField";
import DSDialogActions from "../system-design/DSDialogActions";
import { DSTypography } from "../system-design/Typography";
import GreetingPreview from "./GreetingPreview";

interface GenerateGreetModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (greet: string) => void;
}

const GenerateGreetDialog: React.FC<GenerateGreetModalProps> = ({
  open,
  onClose,
  onSave,
}) => {
  const [recipientName, setRecipientName] = useState<string>("");
  const [eventDescription, setEventDescription] = useState<string>("");
  const [generatedGreet, setGeneratedGreet] = useState<string>("");

  const handleSave = () => {
    onSave(generatedGreet);
    setRecipientName("");
    setEventDescription("");
  };

  const handleCancel = () => {
    setEventDescription("");
    setRecipientName("");
    onClose();
  };

  const handleRecipientNameChange = useCallback((value: string) => {
    setRecipientName(value);
  }, []);

  const handleEventDescriptionChange = useCallback((value: string) => {
    setEventDescription(value);
  }, []);

  return (
    <DSDialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DSDialogTitle>Generate Greeting</DSDialogTitle>
      <DSDialogContent>
        <DSTextField
          fullWidth
          label="Recipient Name"
          margin="dense"
          onChange={(e) => handleRecipientNameChange(e.target.value)}
        />
        <DSTextField
          fullWidth
          label="Event Description"
          margin="dense"
          onChange={(e) => handleEventDescriptionChange(e.target.value)}
        />

        <DSTypography sx={{ mt: 2 }}>Greeting Preview:</DSTypography>
        <GreetingPreview
          recipientName={recipientName}
          eventDescription={eventDescription}
          onGreetGenerated={setGeneratedGreet}
        />
      </DSDialogContent>

      <DSDialogActions>
        <DSSecondaryButton onClick={handleCancel}>Cancel</DSSecondaryButton>
        <DSPrimaryButton onClick={handleSave} disabled={!generatedGreet}>
          Save
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};

export default GenerateGreetDialog;
