import { useState } from "react";
import DSDialog from "../system-design/DSDialog";
import DSDialogActions from "../system-design/DSDialogActions";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import DSSecondaryButton from "../system-design/DSSecondaryButton";
import DSBox from "../system-design/DSBox";
import DSTextField from "../system-design/DSTextField";
import DSTypography from "../system-design/Typography/DSTypography";
import giftClient, { PopulatedClockGift } from "../../api/gift";
import DSCircularProgress from "../system-design/DSCircularProgress";
import { KLYDO_BLACK } from "../../consts/colors";

export type EnterClockDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: (clockGift: PopulatedClockGift) => void;
};

const EnterClockDialog = ({
  open,
  onClose,
  onConfirm,
}: EnterClockDialogProps) => {
  const [clockIdf, setClockIdf] = useState("");
  const [clockIsAlreadyOpened, setClockIsAlreadyOpened] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClockIdfChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setClockIsAlreadyOpened(false);
    setClockIdf(event.target.value);
  };

  const handlePressAdd = async () => {
    try {
      setIsLoading(true);
      const clockGift = await giftClient.getClockGift(clockIdf);
      if (!!clockGift?.registerProduct && !clockGift?.gift) {
        setClockIsAlreadyOpened(true);
        setIsLoading(false);
        return;
      }
      if (clockGift) {
        setIsLoading(false);
        onConfirm(clockGift);
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      return;
    }
  };

  const errorMassege = () => {
    if (isError) {
      return (
        <DSTypography
          fontFamily={"Gilroy-Medium"}
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"24px"}
          color="error"
        >
          Clock ID not found
        </DSTypography>
      );
    }
    if (clockIsAlreadyOpened) {
      return (
        <DSTypography
          fontFamily={"Gilroy-Medium"}
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"24px"}
          color="error"
        >
          Clock is already opened
        </DSTypography>
      );
    }
    return null;
  };

  const handleClose = () => {
    setIsError(false);
    setClockIsAlreadyOpened(false);
    onClose();
  };

  return (
    <DSDialog open={open} onClose={handleClose} title="Enter Clock Friendly ID">
      <DSBox
        sx={{
          padding: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <DSTypography
          fontFamily={"Gilroy-Medium"}
          fontSize={"24px"}
          fontWeight={400}
          lineHeight={"24px"}
        >
          Enter Clock Friendly ID
        </DSTypography>
        <DSTextField
          placeholder="white-hamster-80"
          onChange={handleClockIdfChange}
        />
        {errorMassege()}
      </DSBox>
      <DSDialogActions sx={{ justifyContent: "flex-end", padding: 3, gap: 2 }}>
        <DSSecondaryButton onClick={handleClose}>Cancel</DSSecondaryButton>
        <DSPrimaryButton
          onClick={() => handlePressAdd()}
          disabled={!clockIdf}
          sx={{ padding: "14px 40px" }}
        >
          {isLoading ? (
            <DSCircularProgress size={20} sx={{ color: KLYDO_BLACK }} />
          ) : (
            "Add"
          )}
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};
export default EnterClockDialog;
