import axios, { AxiosProgressEvent } from "axios";

export type UploadFileInput = {
  file: File | string;
  folder: string;
  upload_preset?: string;
  onUploadProgress?: (progress: number) => void;
};

const uploadFile = async (input: UploadFileInput) => {
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/KlydoClock/auto/upload",
      {
        file: input.file,
        folder: input.folder,
        upload_preset: input.upload_preset || "Bezalel_preset",
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          if (input.onUploadProgress) {
            const percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            input.onUploadProgress(percentCompleted);
          }
        },
      },
    );
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const status = error?.response?.status;
    if (status >= 400 && status < 500) {
      alert("Unsupported file. Expected image types are Jpeg, Jpg, Png.");
    } else if (status >= 500) {
      alert(
        "A temporary error occurred on the server. Please try again later.",
      );
    } else {
      alert("Failed to upload file. Please try again.");
    }
    throw new Error("Upload failed");
  }
};

export default uploadFile;
