import React, { useState, useEffect } from "react";
import { Variant, VariantOption } from "../../api/shopify";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import DSTypography from "../system-design/Typography/DSTypography";
import DSBox from "../system-design/DSBox";
import QuantityInput from "../system-design/DSQuantityInput";
import DSFormControl from "../system-design/DSFormControl";
import DSSelect from "../system-design/DSSelect";
import DSMenuItem from "../system-design/DSMenuItem";
import useProductVariants from "./hooks/useProductVariants";
import DSCircularProgress from "../system-design/DSCircularProgress";
import { useNavigate } from "react-router-dom";

type KlydoOrderFormProps = {
  handleBuyNow: (productVariantId: string, quantity: number) => void;
  setWoodType: (value: string) => void;
};

const KlydoOrderForm: React.FC<KlydoOrderFormProps> = ({
  handleBuyNow,
  setWoodType,
}) => {
  const navigate = useNavigate();
  const { data } = useProductVariants();
  const [variantOptions, setVariantOptions] = useState<VariantOption[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<[]>([]);
  const [productVariantId, setProductVariantId] = useState<string>("");
  const [variantsId, setVariantsId] = useState<Variant[]>([]);
  const [quantity, setQuantity] = useState(1);
  const [pressed, setPressed] = useState(false);

  const handleChange = (newVariant: { [key: string]: string }) => {
    if (newVariant["Wood Type"]) {
      setWoodType(newVariant["Wood Type"]);
    }
    setSelectedVariant((prevVariant) => ({
      ...prevVariant,
      ...newVariant,
    }));
  };

  useEffect(() => {
    const fetchProductVariants = async () => {
      setVariantOptions(data?.options);
      setVariantsId(data?.variants);
      setProductVariantId(data?.variants[0].id);
    };

    fetchProductVariants();
  }, []);

  useEffect(() => {
    if (!data) {
      navigate(`/gift?orderId=shopify-redirect`, { replace: true });
    }
  }, []);

  const handleDone = () => {
    setPressed(true);
    const matchingVariant = variantsId.find((variant) => {
      return Object.keys(selectedVariant).every(
        (key) => selectedVariant[key] === variant.variantValues[key],
      );
    });

    if (!matchingVariant) {
      console.error("No matching variant found");
    }

    setProductVariantId(matchingVariant.id);
    handleBuyNow(matchingVariant.id, quantity);
  };

  const handleQuantityChange = (newValue) => {
    setQuantity(newValue);
  };

  if (!variantOptions) {
    return (
      <DSBox
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <DSCircularProgress />
      </DSBox>
    );
  }

  return (
    <DSBox
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "12px",
      }}
    >
      {variantOptions?.map((variant) => (
        <DSBox
          sx={{
            backgroundColor: "black",
            borderRadius: "8px",
            width: "300px",
            textAlign: "left",
          }}
        >
          <DSTypography>{variant.name}</DSTypography>
          <DSBox py={0.2} />
          <DSFormControl
            sx={{
              width: "100%",
              "& .MuiSelect-root": {
                color: "white",
                borderColor: "white",
              },
            }}
          >
            <DSSelect
              value={selectedVariant[variant.name] || variant.values[0]?.name}
              sx={{
                fontFamily: "Roboto-Bold",
              }}
              onChange={(e) =>
                handleChange({ [variant.name]: e.target.value as string })
              }
            >
              {variant.values.map((value: { name: string; price?: number }) => (
                <DSMenuItem key={value.name} value={value.name}>
                  {value.price
                    ? `${value.name} - $${value.price.toFixed(2)}`
                    : value.name}
                </DSMenuItem>
              ))}
            </DSSelect>
          </DSFormControl>
        </DSBox>
      ))}
      <DSBox
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "12px",
          width: "100%",
        }}
      >
        <QuantityInput setQuantityValue={handleQuantityChange} />
        <DSPrimaryButton
          disabled={pressed}
          sx={{ fontSize: "14px" }}
          onClick={handleDone}
        >
          Add to cart
        </DSPrimaryButton>
      </DSBox>
    </DSBox>
  );
};

export default KlydoOrderForm;
