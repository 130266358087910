import React, { useEffect } from "react";
import useGenerateGreetQuery from "./hooks/useGenerateGreetQuery";
import { DSTypography } from "../system-design/Typography";
import DSCircularProgress from "../system-design/DSCircularProgress";

type GreetingPreviewProps = {
  recipientName: string;
  eventDescription: string;
  onGreetGenerated: (greet: string | null) => void;
};

const GreetingPreview = ({
  recipientName,
  eventDescription,
  onGreetGenerated,
}: GreetingPreviewProps) => {
  const {
    data: greet,
    isLoading,
    isError,
  } = useGenerateGreetQuery({ recipientName, eventDescription });

  useEffect(() => {
    onGreetGenerated(isError ? null : greet || null);
  }, [greet, isError]);

  if (!recipientName || !eventDescription) {
    return (
      <DSTypography sx={{ mt: 1 }}>
        Enter details to generate a greeting...
      </DSTypography>
    );
  }

  if (isLoading) {
    return <DSCircularProgress size={24} />;
  }

  if (isError) {
    return (
      <DSTypography color="error" sx={{ mt: 1 }}>
        Something went wrong. Please try again later.
      </DSTypography>
    );
  }

  return (
    <DSTypography sx={{ mt: 1, fontStyle: "italic" }}>{greet}</DSTypography>
  );
};

export default GreetingPreview;
