const firebaseConfig = {
  apiKey: "AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ",
  authDomain: "dazzling-mantra-347515.firebaseapp.com",
  projectId: "dazzling-mantra-347515",
  storageBucket: "dazzling-mantra-347515.appspot.com",
  messagingSenderId: "917257785826",
  appId: "1:917257785826:web:33e6acd03b4fc97148a9bb",
  measurementId: "G-W7R10B8C9R",
};
export default firebaseConfig;
