import firebaseConfig from "./firebaseServiceConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

export class FirebaseService {
  private auth: firebase.auth.Auth;

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.auth = firebase.auth();
  }

  public getToken() {
    return this.auth.currentUser?.getIdToken();
  }

  public getUserId() {
    return this.auth.currentUser?.uid;
  }
}

const firebaseService = new FirebaseService();

export default firebaseService;
