import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginContainer from "./LoginView/LoginContainer";
import withSplashScreen from "./LoginView/SplashScreen";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import AppContext, { Role } from "./AppContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GiftHomePage from "./themes-views/Gifts/GiftHomePage";
import GiftWizard from "./themes-views/Gifts/GiftWizard";
import { GiftContextProvider } from "./themes-views/Gifts/Context/GiftContextProvider";
import GiftDone from "./themes-views/Gifts/GiftDone";
import CustomizeLaterProductPage from "./themes-views/Gifts/CustomizeLaterProductPage";
const queryClient = new QueryClient();

const App = (props: { user: firebase.User & Role }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={{ user: props.user }}>
        <BrowserRouter>
          <GiftContextProvider>
            <Routes>
              <Route path="login" element={<LoginContainer />} />
              <Route path="/gift" element={<GiftHomePage />} />
              <Route path="/gift/wizard" element={<GiftWizard />} />
              <Route path="/gift/done" element={<GiftDone />} />
              <Route
                path="/gift/productPage"
                element={<CustomizeLaterProductPage />}
              />
              <Route path="*" element={<Navigate to={"/gift"} />} />
            </Routes>
          </GiftContextProvider>
        </BrowserRouter>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default withSplashScreen(App);
