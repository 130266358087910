import uploadFile from "../../../utils/uploadFile";

interface UseFileUploadOptions {
  folder: string;
  uploadPreset?: string;
  setUrl?: (url: string) => void;
  setLoading?: (loading: boolean) => void;
  onSuccess?: (url: string) => void;
  onError?: () => void;
}

const useFileUpload = ({
  folder,
  uploadPreset,
  setUrl,
  setLoading,
  onSuccess,
  onError,
}: UseFileUploadOptions) => {
  const readAndUploadFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        setLoading(true);
        setUrl(reader.result as string);
        const response = await uploadFile({
          file: reader.result as string,
          folder,
          upload_preset: uploadPreset,
        });

        setUrl(response.secure_url);
        onSuccess?.(response.secure_url);
      } catch (error) {
        onError?.();
      } finally {
        setLoading(false);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      alert("Invalid file type. Only MP4 and GIF files are allowed.");
      return;
    }
    if (file) {
      readAndUploadFile(file);
    }
  };

  return {
    handleFileSelection,
  };
};

export default useFileUpload;
