import giftClient, { GenerateGreetPayload } from "../../../api/gift";
import useEditorQuery from "../../../hooks/useEditorQuery";

const useGenerateGreetQuery = ({
  recipientName,
  eventDescription,
}: GenerateGreetPayload) => {
  return useEditorQuery({
    queryKey: ["generate-greet", recipientName, eventDescription],
    queryFn: async () =>
      giftClient.generateGreet({ recipientName, eventDescription }),
    enabled: !!recipientName && !!eventDescription,
  });
};
export default useGenerateGreetQuery;
