import { useTheme } from "@mui/material";
import { useGiftContext } from "../Context/GiftContextProvider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useFileUpload from "./useFileUpload";
import { Dayjs } from "dayjs";
import useKlydo from "../../../hooks/useKlydo";

const useAdminGiftForm = () => {
  const defaultPhoto =
    "https://res.cloudinary.com/klydoclock/image/upload/profile/defaultPhoto_mc2iv6.png";
  const theme = useTheme();
  const {
    setKlydoGiftProps,
    setSelectedKlydoGreet,
    klydoGiftProps,
    customKlydoId,
    setCustomKlydoId,
    resetSenderImageUrl,
    setLoading,
  } = useGiftContext();
  const { data: customKlydo } = useKlydo(customKlydoId);
  const [greeting, setGreeting] = useState(
    klydoGiftProps?.greetingContent || "",
  );
  const [name, setName] = useState(klydoGiftProps?.senderName || "");
  const [selectedOption, setSelectedOption] = useState(
    klydoGiftProps?.pendulumLogoUrl ? "logo" : "text",
  );
  const [profileUrl, setProfileUrl] = useState(klydoGiftProps?.senderImageUrl);
  const [pendulumLogoUrl, setPendulumLogoUrl] = useState(
    klydoGiftProps?.pendulumLogoUrl || "",
  );
  const [localCustomKlydoId, setLocalCustomKlydoId] = useState(
    klydoGiftProps?.klydoId,
  );

  const [profileImageOption, setProfileImageOption] = useState("defualtPhoto");
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const { handleFileSelection: handleProfileUpload } = useFileUpload({
    folder: "profile",
    uploadPreset: "GiftPicture",
    setUrl: setProfileUrl,
    setLoading: setLoading,
    onSuccess: (url) => {
      setKlydoGiftProps({
        senderImageUrl: url,
      });
    },
  });

  const { handleFileSelection: handleLogoUpload } = useFileUpload({
    folder: "gift_pendulum_logo",
    uploadPreset: "GiftPendulumLogo",
    setUrl: setPendulumLogoUrl,
    setLoading: setLoading,
    onSuccess: (url) => {
      setKlydoGiftProps({
        pendulumLogoUrl: url,
      });
    },
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "text") {
      setPendulumLogoUrl("");
    } else {
      setName("");
    }
  };

  const handleProfileClick = () => fileInputRef.current?.click();
  const handleLogoClick = () => logoInputRef.current?.click();

  const handleCustomKlydo = (klydoId: string) => {
    setLocalCustomKlydoId(klydoId);
    setCustomKlydoId(klydoId);
    setKlydoGiftProps({
      ...klydoGiftProps,
      klydoId: klydoId,
    });
  };

  const handleGreetChange = (greet: string) => {
    if (greet.length > 160) return alert("Greeting is too long");
    setGreeting(greet);
    setKlydoGiftProps({
      ...klydoGiftProps,
      greetingContent: greet,
    });
  };

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14) return alert("Name is too long");
    setName(senderName);
    setKlydoGiftProps({
      senderName: senderName,
    });
  };

  const handleDateChange = (selectedDate: Dayjs) => {
    setKlydoGiftProps({
      date: selectedDate?.valueOf(),
    });
  };

  const handleRemoveProfile = () => {
    setProfileUrl(defaultPhoto);
    resetSenderImageUrl();
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleRemoveLogo = () => {
    setPendulumLogoUrl("");
    setKlydoGiftProps({
      pendulumLogoUrl: "",
    });
    if (logoInputRef.current) logoInputRef.current.value = "";
  };

  const handlePickProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleProfileUpload(event);
  };

  const handlePickLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleLogoUpload(event);
  };

  useEffect(() => {
    if (customKlydo) {
      setSelectedKlydoGreet(customKlydo);
    }
  }, [customKlydo]);

  const linesLimit = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const lines = greeting.split("\n");

      if (event.key === "Enter" && lines.length >= 8) {
        event.preventDefault();
      }
    },
    [greeting],
  );

  return {
    theme,
    greeting,
    name,
    selectedOption,
    profileUrl,
    pendulumLogoUrl,
    fileInputRef,
    logoInputRef,
    localCustomKlydoId,
    klydoGiftProps,
    defaultPhoto,
    profileImageOption,
    setProfileImageOption,
    handleProfileClick,
    handleLogoClick,
    handleCustomKlydo,
    handleOptionChange,
    handleGreetChange,
    handleNameChange,
    handleDateChange,
    handleRemoveProfile,
    handleRemoveLogo,
    handlePickProfile,
    handlePickLogo,
    linesLimit,
  };
};
export default useAdminGiftForm;
