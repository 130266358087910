import { useEffect, useRef } from "react";
import { isVideo, parseCloudinaryUrl } from "../../utils";
import { useOnScreen } from "../../hooks";
import IconTint from "react-icon-tint";
import { isMobile } from "react-device-detect";
import DSTypography from "../system-design/Typography/DSTypography";
import DSBox from "../system-design/DSBox";
import DSAvatar from "../system-design/DSAvatar";
import { KLYDO_BLACK } from "../../consts/colors";
import { Klydo } from "../../context/klydo/KlydoTypes";
import { KlydoGiftProps } from "../../api/gift";

export type KlydoPreviewProps = {
  klydo: Partial<Klydo>;
  gift: Partial<KlydoGiftProps>;
  isToggled?: boolean;
  woodType?: string;
};

const secondsForMin = 3600;
const secondsForHour = 43200;

const KlydoPreview = ({
  klydo,
  gift,
  isToggled = false,
  woodType,
}: KlydoPreviewProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const ii = useRef<NodeJS.Timeout>();
  const loopUrl = parseCloudinaryUrl({
    url: klydo?.convertedLoopUrl || klydo?.loopUrl,
    isStatic: false,
    size: 400,
  });
  const handsColor = klydo?.theme?.handsColor;
  const backgroundColor = klydo?.theme?.backgroundColor;
  const pendulumColor = klydo?.theme?.pendulumColor;
  const pendulumRodColor = klydo?.theme?.pendulumRodColor;
  const senderName = gift?.senderName;
  const senderImageUrl = gift?.senderImageUrl;
  const greetingContent = gift?.greetingContent;
  const date = gift?.date;

  const handleLoopUrl = () => {
    if (loopUrl && isVideo(loopUrl) === "image") {
      return (
        <img
          loading="lazy"
          src={loopUrl}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "fill",
            borderRadius: "50%",
            transform: "rotateY(10deg) rotateX(10deg)",
          }}
        />
      );
    } else if (loopUrl && isVideo(loopUrl) === "video") {
      return (
        <video
          muted={true}
          src={loopUrl}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            objectFit: "fill",
            transform: "rotateY(20deg) rotateX(10deg)",
          }}
          autoPlay
          loop
          playsInline
        />
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            aspectRatio: "1",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>This file type is not supported</p>
        </div>
      );
    }
  };

  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current) {
        handMin.current.style.transform = `rotate(${dgmin}deg)`;
      }
      if (handMinS.current) {
        handMinS.current.style.transform = `rotate(${dgmin}deg)`;
      }
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current) {
        handHour.current.style.transform = `rotate(${dgh}deg)`;
      }
      if (handHourS.current) {
        handHourS.current.style.transform = `rotate(${dgh}deg)`;
      }
    };

    ii.current = setInterval(updHands, 2000);
    updHands();
    return () => clearInterval(ii.current);
  }, [isVisible]);

  return (
    <div
      ref={ref}
      style={{ position: "absolute", width: "100%", height: "100%" }}
    >
      <div
        style={{
          position: "absolute",
          width: "95%",
          height: "95%",
          backgroundColor: backgroundColor,
        }}
      />
      <div
        style={{
          left: "38%",
          top: "28%",
          width: "30%",
          height: "60%",
          position: "absolute",
          transformOrigin: "50% 0px",
          animation: "2s ease-in-out 0s infinite normal none running swing-4",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "58%",
            transformOrigin: "50% 0px",
            backgroundColor: pendulumRodColor,
            width: "4%",
            height: "71%",
          }}
        />

        <DSBox
          position={"absolute"}
          borderRadius="50%"
          boxShadow={"rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px"}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "120%",
            height: "36%",
            bottom: -13,
            backgroundColor: pendulumColor,
            transform: "rotateY(30deg) rotateX(1deg)",
          }}
        >
          <DSBox
            sx={{
              paddingY: { xs: 0.5, md: 1 },
            }}
          />
          <IconTint
            src="https://res.cloudinary.com/klydoclock/image/upload/v1727775595/pack_pendulum_covers/hmzb88yhzidkjujf8xbt.png"
            color={backgroundColor}
            maxWidth={isMobile ? 45 : 60}
            maxHeight={isMobile ? 40 : 50}
          />
          <DSTypography
            fontFamily={"Gilroy-SemiBold"}
            fontSize={isMobile ? "6.5px" : "8px"}
            color={backgroundColor}
            sx={{ marginTop: isMobile ? -0.4 : -0.7 }}
          >
            {senderName}
          </DSTypography>
        </DSBox>
      </div>
      <div
        style={{
          width: "78.5%",
          height: "51%",
          top: isMobile ? "4.2%" : "4.5%",
          left: isMobile ? "16.5%" : "14.5%",
          position: "absolute",
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          backgroundColor: backgroundColor,
        }}
      >
        {isToggled && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: KLYDO_BLACK,
              borderRadius: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              transform: "rotateY(25deg) rotateX(4deg)",
              zIndex: 10,
            }}
          >
            <DSBox
              sx={{
                zIndex: 11,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DSAvatar
                sx={{
                  width: { xs: 45, md: 60 },
                  height: { xs: 45, md: 60 },
                  backgroundColor: KLYDO_BLACK,
                  position: "absolute",
                  top: 15,
                }}
              >
                <img
                  src={senderImageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                    borderRadius: "50%",
                    backgroundColor: KLYDO_BLACK,
                  }}
                />
              </DSAvatar>
              <DSBox py={1.5} />
              <DSTypography
                fontFamily={"Gilroy-SemiBold"}
                fontSize={isMobile ? "9px" : "10.5px"}
                maxWidth={isMobile ? "140px" : "160px"}
                sx={{
                  position: "relative",
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                {greetingContent}
              </DSTypography>
              {date && (
                <DSTypography
                  fontFamily={"Gilroy-SemiBold"}
                  fontSize={isMobile ? "8.5px" : "10px"}
                  sx={{
                    position: "absolute",
                    bottom: 20,
                  }}
                >
                  {new Date(date).toLocaleString("default", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </DSTypography>
              )}
            </DSBox>
          </div>
        )}
        <div>{handleLoopUrl()}</div>
      </div>
      <time
        dateTime="2023-06-30T04:48:07.668Z"
        style={{
          padding: "38.5%",
          top: "6.95%",
          position: "absolute",
          left: "18%",
        }}
      >
        <div // hour shadow
          ref={handHourS}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "0.4%",
            top: "1.2%",
            transformOrigin: "center",
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "10px",
              width: "4%",
              height: "33%",
              bottom: "46%",
              left: "48%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          />
        </div>
        <div // hour
          ref={handHour}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            transformOrigin: "center",
            zIndex: 3,
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "10px",
              width: "4%",
              height: "33%",
              bottom: "46%",
              left: "48%",
              backgroundColor: handsColor,
            }}
          />
        </div>
        <div // minute shadow
          ref={handMinS}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            left: "1.2%",
            top: "1.4%",
            transformOrigin: "center",
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "3%",
              height: "43%",
              bottom: "45%",
              left: "48.75%",
              background:
                "radial-gradient(ellipse at center, #000000 0%, #00000000 100%)",
            }}
          />
        </div>
        <div // minute
          ref={handMin}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 3,
            transformOrigin: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              borderRadius: "4px",
              width: "3%",
              height: "43%",
              bottom: "45%",
              left: "48.75%",
              backgroundColor: handsColor,
            }}
          />
        </div>
      </time>
      <img
        loading="lazy"
        src={
          woodType === "Walnut"
            ? require("./assets/clock_walnut.png")
            : require("./assets/clock_oak.png")
        }
        alt="Klydo Clock"
        style={{
          top: isMobile ? -95 : -150,
          left: isMobile ? -162 : -265,
          width: "265%",
          height: "auto",
          position: "absolute",
        }}
      />
    </div>
  );
};

export default KlydoPreview;
