import React from "react";
import { Typography } from "@mui/material";

const DSSubtitleTypography = (
  props: React.ComponentProps<typeof Typography>,
) => {
  return <Typography variant="subtitle1" {...props} />;
};

export default DSSubtitleTypography;
