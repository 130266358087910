import { isMobile } from "react-device-detect";
import DSBox from "../system-design/DSBox";
import useAdminGiftForm from "./hooks/useAdminGiftForm";
import DSTypography from "../system-design/Typography/DSTypography";
import DSIconButton from "../system-design/DSIconButton";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FileUploadOutlined } from "@mui/icons-material";
import DSTextField from "../system-design/DSTextField";
import DSFormControl from "../system-design/DSFormControl";
import DSRadioGroup from "../system-design/DSRadioGroup";
import DSRadio from "../system-design/DSRadio";
import DSFormControlLabel from "../system-design/DSFormControlLabel";
import DSChip from "../system-design/DSChip";

const AdminGiftForm = () => {
  const {
    theme,
    greeting,
    name,
    selectedOption,
    profileUrl,
    pendulumLogoUrl,
    fileInputRef,
    logoInputRef,
    localCustomKlydoId,
    klydoGiftProps,
    defaultPhoto,
    profileImageOption,
    setProfileImageOption,
    handleProfileClick,
    handleLogoClick,
    handleCustomKlydo,
    handleOptionChange,
    handleGreetChange,
    handleNameChange,
    handleDateChange,
    handleRemoveProfile,
    handleRemoveLogo,
    handlePickProfile,
    handlePickLogo,
    linesLimit,
  } = useAdminGiftForm();
  return (
    <DSBox
      width={"100%"}
      sx={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: { xs: 2, md: 10 },
        paddingBottom: { xs: 10, md: 0 },
      }}
    >
      <DSBox
        gap={isMobile ? 0.5 : 5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row-reverse",
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <DSBox
          width={isMobile ? "80%" : "350px"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Change to custom klydo
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Enter Klydo ID"
              value={localCustomKlydoId}
              onChange={(e) => handleCustomKlydo(e.target.value)}
              fullWidth
              sx={{ backgroundColor: "#212121", color: "#C9C9C9" }}
            />
            <DSBox py={1} />

            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Write your greeting
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Dear Ron..."
              value={greeting}
              onChange={(e) => handleGreetChange(e.target.value)}
              multiline
              fullWidth
              rows={5}
              maxRows={8}
              slotProps={{
                input: {
                  inputProps: { maxLength: 160 },
                  style: {
                    padding: "20px",
                  },
                  onKeyDown: linesLimit,
                },
              }}
              sx={{
                backgroundColor: "#212121",
                ".MuiInputBase-input": {
                  overflowWrap: "break-word",
                  fontSize: "15px",
                  maxWidth: "300px",
                  display: "block",
                },
              }}
            />
            <DSBox py={0.5} />
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              {greeting.length}/160
            </DSTypography>
          </DSBox>
          <DSBox py={1} />
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Date of occasion
            </DSTypography>
            <DSBox py={0.5} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={!klydoGiftProps?.date ? "MM/DD/YYYY" : ""}
                value={
                  klydoGiftProps?.date
                    ? dayjs(new Date(klydoGiftProps?.date))
                    : null
                }
                onChange={(newDate) => handleDateChange(newDate)}
                sx={{
                  backgroundColor: "#212121",
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </DSBox>
          <DSBox py={3} />

          <DSTypography
            variant="h6"
            fontSize={"16px"}
            color={"#C9C9C9"}
            fontFamily={"Gilroy-SemiBold"}
          >
            Identity:
          </DSTypography>
          <DSFormControl>
            <DSRadioGroup
              row
              value={selectedOption}
              onChange={handleOptionChange}
              sx={{
                color: "#C9C9C9",
              }}
            >
              <DSFormControlLabel
                value="text"
                disabled={!!pendulumLogoUrl}
                control={<DSRadio />}
                label="Name"
                sx={{
                  ".MuiFormControlLabel-label": {
                    color: "#C9C9C9",
                  },
                }}
              />
              <DSFormControlLabel
                value="logo"
                disabled={!!name}
                control={<DSRadio />}
                label="Pendulum Logo"
                sx={{
                  ".MuiFormControlLabel-label": {
                    color: "#C9C9C9",
                  },
                }}
              />
            </DSRadioGroup>
          </DSFormControl>
          {selectedOption === "text" ? (
            <DSBox>
              <DSTextField
                placeholder="Enter Name"
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
                required
                fullWidth
                slotProps={{
                  input: {
                    inputProps: { maxLength: 14 },
                  },
                }}
                sx={{
                  backgroundColor: "#212121",
                  ".MuiInputBase-input": {
                    fontSize: "15px",
                  },
                }}
              />
            </DSBox>
          ) : (
            <DSBox
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 4,
              }}
            >
              <DSTextField
                disabled
                fullWidth
                placeholder={pendulumLogoUrl ? "" : "Upload a file"}
                InputProps={{
                  startAdornment: pendulumLogoUrl ? (
                    <DSChip
                      label={pendulumLogoUrl.split("/").pop()}
                      onDelete={handleRemoveLogo}
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    />
                  ) : null,
                  endAdornment: (
                    <DSIconButton
                      disabled={!!pendulumLogoUrl}
                      onClick={handleLogoClick}
                    >
                      <FileUploadOutlined />
                    </DSIconButton>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    cursor: "pointer",
                  },
                }}
              />
              <input
                type="file"
                accept=".png"
                ref={logoInputRef}
                onChange={handlePickLogo}
                style={{ display: "none" }}
              />

              {pendulumLogoUrl && (
                <DSBox
                  sx={{
                    marginTop: -3,
                  }}
                >
                  <img
                    src={pendulumLogoUrl}
                    alt="Preview"
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: "contain",
                      borderRadius: 50,
                      border: "1px solid #ccc",
                    }}
                  />
                </DSBox>
              )}
            </DSBox>
          )}
          <DSBox py={4} />
          <DSBox
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 4,
            }}
          >
            <DSBox>
              <DSFormControl>
                <DSRadioGroup
                  row
                  value={profileImageOption}
                  onChange={(e) => setProfileImageOption(e.target.value)}
                  sx={{
                    color: "#C9C9C9",
                  }}
                >
                  <DSFormControlLabel
                    value="defualtPhoto"
                    control={<DSRadio />}
                    label="Defualt photo"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: "#C9C9C9",
                      },
                    }}
                  />
                  <DSFormControlLabel
                    value="userPhoto"
                    control={<DSRadio />}
                    label="Your photo"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: "#C9C9C9",
                      },
                    }}
                  />
                </DSRadioGroup>
              </DSFormControl>

              <DSTextField
                disabled
                fullWidth
                placeholder={profileUrl == defaultPhoto ? "Upload a file" : ""}
                InputProps={{
                  startAdornment:
                    profileUrl == defaultPhoto ? null : (
                      <DSChip
                        label={profileUrl.split("/").pop()}
                        onDelete={handleRemoveProfile}
                        sx={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      />
                    ),
                  endAdornment: (
                    <DSIconButton
                      disabled={profileUrl !== defaultPhoto}
                      onClick={handleProfileClick}
                    >
                      <FileUploadOutlined />
                    </DSIconButton>
                  ),
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    cursor: "pointer",
                  },
                }}
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handlePickProfile}
                style={{ display: "none" }}
              />
            </DSBox>
            <DSBox sx={{ paddingTop: 2 }}>
              <img
                src={profileUrl}
                alt="Preview"
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "contain",
                  borderRadius: 50,
                  border: "1px solid #ccc",
                }}
              />
            </DSBox>
          </DSBox>
        </DSBox>
      </DSBox>
    </DSBox>
  );
};
export default AdminGiftForm;
