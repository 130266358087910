import { Klydo } from "../../context/klydo/KlydoTypes";
import { parseCloudinaryUrl } from "../../utils";
import DSImageList from "../system-design/DSImageList";
import DSImageListItem from "../system-design/DSImageListItem";
import { useTheme } from "@mui/material";
import { useGiftContext } from "./Context/GiftContextProvider";
import DSBox from "../system-design/DSBox";
import { KLYDO_YELLOW } from "../../consts/colors";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";

const KlydoImage = ({
  id,
  loopUrl,
  convertedLoopUrl,
}: {
  id: string;
  loopUrl: string;
  convertedLoopUrl: string;
}) => {
  const [videoSrc, setVideoSrc] = useState<string>("");

  const url = parseCloudinaryUrl({
    url: convertedLoopUrl || loopUrl,
    isStatic: false,
    size: 150,
  });

  useEffect(() => {
    // Create an AbortController to cancel the fetch if component unmounts
    const abortController = new AbortController();
    const { signal } = abortController;

    (async () => {
      try {
        const response = await fetch(url, { signal });
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
        setVideoSrc(blobUrl);
      } catch (err: unknown) {
        if ((err as Error).name === "AbortError") {
          console.log("Fetch aborted:", url);
        } else {
          console.error("Fetch error:", err);
        }
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [url]);

  return (
    <video
      key={id}
      muted
      autoPlay
      loop
      playsInline
      height={96}
      width={96}
      style={{
        borderRadius: "50%",
        width: "100%",
        height: "100%",
        objectFit: "fill",
      }}
      src={videoSrc}
    />
  );
};
const GiftKlydoGreet = () => {
  const theme = useTheme();
  const {
    selectedKlydoGreet,
    setSelectedKlydoGreet,
    selectedOccasion,
    klydoGiftProps,
    next,
    setKlydoGiftProps,
  } = useGiftContext();

  const handleSelectedKlydoGreet = (klydo: Klydo) => {
    setSelectedKlydoGreet(klydo);
    setKlydoGiftProps({
      ...klydoGiftProps,
      klydoId: klydo.id,
    });
    next();
  };

  return (
    <DSBox
      sx={{
        paddingTop: { xs: 1, md: 8 },
        justifyContent: "center",
        display: "flex",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <DSImageList
        cols={isMobile ? 2 : 5}
        gap={isMobile ? 15 : 50}
        sx={{
          width: { xs: 300, md: 850 },
        }}
      >
        {selectedOccasion?.klydos?.map((klydoGreet) => (
          <DSImageListItem
            key={klydoGreet.loopUrl}
            onClick={() => handleSelectedKlydoGreet(klydoGreet)}
            style={{
              width: 129,
              height: 129,
            }}
            sx={{
              border: `4px solid ${selectedKlydoGreet?.id === klydoGreet.id ? KLYDO_YELLOW : "white"}`,
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            {KlydoImage(klydoGreet)}
          </DSImageListItem>
        ))}
      </DSImageList>
    </DSBox>
  );
};

export default GiftKlydoGreet;
