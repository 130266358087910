import { useTheme } from "@mui/material";
import DSBox from "../system-design/DSBox";
import { useGiftContext } from "./Context/GiftContextProvider";
import GiftView from "./GiftView";
import DSTypography from "../system-design/Typography/DSTypography";
import { useState } from "react";
import DSSwitch from "../system-design/DSSwitch";
import { isIOS, isMobile } from "react-device-detect";

const GiftViewContainer = () => {
  const theme = useTheme();

  const { klydoGiftProps, selectedKlydoGreet } = useGiftContext();
  const [showGreeting, setShowGreeting] = useState(false);
  const handleToggle = (event) => {
    setShowGreeting(event.target.checked);
  };
  return (
    <DSBox
      sx={{
        paddingTop: { xs: "20px", md: "30px" },
        paddingBottom: { xs: "10x", md: "0px" },
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {isMobile ? (
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: isIOS && isMobile ? "250px" : "230px",
              height: isIOS && isMobile ? "420px" : "380px",
              position: "relative",
            }}
          >
            <GiftView
              gift={klydoGiftProps}
              klydo={selectedKlydoGreet}
              isToggled={showGreeting}
            />
          </div>
          <DSBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <DSTypography
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={showGreeting ? "#A5A5A5" : "#FFFFFF"}
            >
              Greet
            </DSTypography>

            <DSSwitch checked={showGreeting} onChange={handleToggle} />

            <DSTypography
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={showGreeting ? "#FFFFFF" : "#A5A5A5"}
            >
              Message
            </DSTypography>
          </DSBox>
        </DSBox>
      ) : (
        <DSBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
          }}
        >
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "290px",
                height: "483px",
                position: "relative",
              }}
            >
              <GiftView gift={klydoGiftProps} klydo={selectedKlydoGreet} />
            </div>
            <DSTypography
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={"#FFFFFF"}
            >
              Klydo
            </DSTypography>
          </DSBox>
          <DSBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "290px",
                height: "483px",
                position: "relative",
              }}
            >
              <GiftView
                gift={klydoGiftProps}
                klydo={selectedKlydoGreet}
                isToggled={true}
              />
            </div>
            <DSTypography
              fontFamily={"Gilroy-SemiBold"}
              fontSize={"16px"}
              color={"#FFFFFF"}
            >
              Greeting
            </DSTypography>
          </DSBox>
        </DSBox>
      )}
    </DSBox>
  );
};
export default GiftViewContainer;
