import DSDialog from "../system-design/DSDialog";
import DSDialogContent from "../system-design/DSDialogContent";
import DSDialogTitle from "../system-design/DSDialogTitle";
import {
  DSSubtitleTypography,
  DSTitleTypography,
} from "../system-design/Typography";

type ErrorDialogProps = {
  errorTitle: string;
  errorReason: string;
  isErrorDialogOpen: boolean;
  handleClose: () => void;
  onClick?: () => void;
};
const ErrorDialog = ({
  errorTitle,
  errorReason,
  isErrorDialogOpen,
  handleClose,
  onClick,
}: ErrorDialogProps) => {
  return (
    <DSDialog
      maxWidth="md"
      open={isErrorDialogOpen}
      onClose={() => handleClose()}
    >
      <DSDialogTitle>
        <DSTitleTypography color="error">{errorTitle}</DSTitleTypography>
      </DSDialogTitle>
      <DSDialogContent>
        <DSSubtitleTypography
          color="error"
          onClick={onClick}
          sx={{
            ...(onClick && {
              textDecorationLine: "underline",
              cursor: "pointer",
            }),
          }}
        >
          {errorReason}
        </DSSubtitleTypography>
      </DSDialogContent>
    </DSDialog>
  );
};
export default ErrorDialog;
