import background from "./assets/background.png";
import logo from "./assets/klydoclock-black.png";
import DSAppBar from "../system-design/DSAppBar";

import DSBox from "../system-design/DSBox";
import { isMobile } from "react-device-detect";
import { KLYDO_BLACK } from "../../consts/colors";
import DSTypography from "../system-design/Typography/DSTypography";
import DSPrimaryButton from "../system-design/DSPrimaryButtom";
import { Link } from "@mui/material";
import useGiftHomePage from "./hooks/useGiftHomePage";
import { SplashScreen } from "../../LoginView/SplashScreen";
import EnterClockDialog from "./EnterClockDialog";

const GiftHomePage = () => {
  const {
    prsonalInfo,
    handleCreateGreet,
    navigate,
    isLoading,
    isShopifyRedirect,
    enterClockDialogOpen,
    handleCloseEnterClockDialog,
    handleClockConfirm,
  } = useGiftHomePage();

  if (isLoading) return SplashScreen();

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundColor: KLYDO_BLACK,
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <DSAppBar
        onClick={() => {
          window.location.href = "https://www.klydoclock.com/";
        }}
        // position="fixed"
        color="transparent"
        sx={{
          cursor: "pointer",
          paddingTop: { xs: 3, md: 5 },
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <img width={isMobile ? "150" : "250"} src={logo} />
      </DSAppBar>
      <DSBox
        sx={{
          position: "absolute",
          bottom: 0,
          marginBottom: { xs: 2, md: 8 },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {prsonalInfo && (
          <DSTypography
            lineHeight={1}
            fontFamily={"Gilroy-Bold"}
            color="#FFFFFF"
            textAlign="center"
            sx={{
              maxWidth: { xs: "300px", md: "500px" },
              fontSize: { xs: "30px", md: "40px" },
              fontWeight: 600,
            }}
          >
            {`Hi ${prsonalInfo},`}
          </DSTypography>
        )}
        <DSTypography
          lineHeight={1.2}
          fontFamily={"Gilroy-Bold"}
          color="#FFFFFF"
          textAlign="center"
          mb={2}
          sx={{
            maxWidth: { xs: "300px", md: "500px" },
            fontSize: { xs: "30px", md: "40px" },
            fontWeight: 600,
          }}
        >
          {`Welcome to KlydoGreet`}
        </DSTypography>

        <DSTypography
          fontFamily={"Gilroy-Medium"}
          color="#FFFFFF"
          textAlign="center"
          mb={3}
          sx={{
            maxWidth: { xs: "300px", md: "500px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
        >
          Add a personal touch to your gift with your choice of animated
          greeting card and a custom note. It usually takes 5 minutes.
        </DSTypography>
        <DSPrimaryButton
          sx={{
            width: { xs: "220px", md: "240px" },
            fontSize: { xs: "14px", md: "16px" },
          }}
          onClick={handleCreateGreet}
        >
          Create a KlydoGreet
        </DSPrimaryButton>
        {isShopifyRedirect && (
          <DSTypography
            fontFamily={"Gilroy-Bold"}
            color="#FFFFFF"
            textAlign="center"
            mt={3}
            maxWidth={"300px"}
            sx={{
              fontSize: { xs: "14px", md: "16px" },
            }}
          >
            {"or "}
            <Link
              color="inherit"
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/gift/productPage")}
            >
              Buy Now, Customize Later
            </Link>
          </DSTypography>
        )}
        <EnterClockDialog
          open={enterClockDialogOpen}
          onClose={handleCloseEnterClockDialog}
          onConfirm={handleClockConfirm}
        />
      </DSBox>
    </div>
  );
};

export default GiftHomePage;
