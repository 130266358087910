import { useTheme } from "@mui/material";
import { useGiftContext } from "../Context/GiftContextProvider";
import { useCallback, useRef, useState } from "react";
import useFileUpload from "./useFileUpload";
import { Dayjs } from "dayjs";

const useGiftForm = () => {
  const defaultPhoto =
    "https://res.cloudinary.com/klydoclock/image/upload/profile/defaultPhoto_mc2iv6.png";
  const theme = useTheme();
  const { setKlydoGiftProps, klydoGiftProps, resetSenderImageUrl, setLoading } =
    useGiftContext();
  const [greeting, setGreeting] = useState(
    klydoGiftProps?.greetingContent || "",
  );
  const [name, setName] = useState(klydoGiftProps?.senderName || "");
  const [profileUrl, setProfileUrl] = useState(klydoGiftProps?.senderImageUrl);
  const [generateGreetDialogOpen, setGenerateGreetDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const fileInputRef = useRef(null);
  const { handleFileSelection: handleProfileUpload } = useFileUpload({
    folder: "profile",
    uploadPreset: "GiftPicture",
    setUrl: setProfileUrl,
    setLoading: setLoading,
    onSuccess: (url) => {
      setKlydoGiftProps({
        senderImageUrl: url,
      });
    },
    onError: () => {
      setProfileUrl(defaultPhoto);
      resetSenderImageUrl();
      if (fileInputRef.current) fileInputRef.current.value = "";
    },
  });

  const handleAvatarClick = () => fileInputRef.current?.click();

  const handleGreetChange = (greet: string) => {
    if (greet.length > 160) {
      setErrorDialogOpen(true);
      return;
    }
    setGreeting(greet);
    setKlydoGiftProps({
      greetingContent: greet,
    });
  };

  const handleCloseErrorDialog = () => setErrorDialogOpen(false);

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14) return alert("Name is too long");
    setName(senderName);
    setKlydoGiftProps({
      senderName: senderName,
    });
  };

  const handleDateChange = (selectedDate: Dayjs) => {
    setKlydoGiftProps({
      date: selectedDate?.valueOf(),
    });
  };

  const handleRemoveProfile = () => {
    setProfileUrl(defaultPhoto);
    resetSenderImageUrl();
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handlePickProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleProfileUpload(event);
  };

  const linesLimit = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const lines = greeting.split("\n");

      if (event.key === "Enter" && lines.length >= 8) {
        event.preventDefault();
      }
    },
    [greeting],
  );

  const handleClickGenerateGreet = () => {
    setGenerateGreetDialogOpen(true);
  };

  const handleCloseGenerateGreetDialog = () => {
    setGenerateGreetDialogOpen(false);
  };

  const handleSaveGenerateGreet = useCallback(
    (greet: string) => {
      handleGreetChange(greet);
      setGenerateGreetDialogOpen(false);
    },
    [handleGreetChange],
  );

  return {
    theme,
    greeting,
    name,
    profileUrl,
    fileInputRef,
    klydoGiftProps,
    defaultPhoto,
    handleAvatarClick,
    handleGreetChange,
    handleNameChange,
    handleDateChange,
    handleRemoveProfile,
    handlePickProfile,
    linesLimit,
    handleClickGenerateGreet,
    generateGreetDialogOpen,
    handleCloseGenerateGreetDialog,
    handleSaveGenerateGreet,
    errorDialogOpen,
    handleCloseErrorDialog,
  };
};
export default useGiftForm;
