import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Klydo } from "../../../context/klydo/KlydoTypes";
import { GiftCategory, KlydoGiftProps } from "../../../api/gift";
import useGiftCategories from "../hooks/useGiftCategories";
import axios from "axios";
import { parseCloudinaryUrl, useHistory } from "../../../utils";
import { ALLOWED_ROLES, SplashScreen } from "../../../LoginView/SplashScreen";
import { getToken } from "../../../api/baseRequest";
import AppContext from "../../../AppContext";
import steps from "../Steps/steps";

export type GiftContextType = {
  selectedOccasion?: GiftCategory;
  selectedKlydoGreet?: Klydo;
  customKlydoId?: string;
  klydoGiftProps: KlydoGiftProps;
  next: () => void;
  back: () => void;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOccasion: React.Dispatch<React.SetStateAction<GiftCategory>>;
  setSelectedKlydoGreet: React.Dispatch<React.SetStateAction<Klydo>>;
  setCustomKlydoId: React.Dispatch<React.SetStateAction<string>>;
  setKlydoGiftProps: (props: Partial<KlydoGiftProps>) => void;
  resetSenderImageUrl: () => void;
  giftCategories: GiftCategory[];
  admin: boolean;
};
const GiftContext = createContext<GiftContextType>({} as GiftContextType);

export const useGiftContext = () => useContext(GiftContext);

export const GiftContextProvider = ({ children }) => {
  const defaultPhoto =
    "https://res.cloudinary.com/klydoclock/image/upload/profile/defaultPhoto_mc2iv6.png";
  const { data: giftCategories, isLoading } = useGiftCategories();
  const [admin, setAdmin] = useState(false);
  const { user } = useContext(AppContext);
  const { setQueryParam, search } = useHistory();

  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedOccasion, setSelectedOccasion] = useState<GiftCategory>();
  const [selectedKlydoGreet, setSelectedKlydoGreet] = useState<Klydo>();
  const [klydoGiftProps, setKlydoGiftPropsState] = useState<KlydoGiftProps>({
    senderImageUrl: defaultPhoto,
    senderName: "",
    greetingContent: "",
    giftId: "",
    orderId: "",
    clockId: "",
    klydoId: "",
  });

  const setKlydoGiftProps = (props: Partial<KlydoGiftProps>) => {
    setKlydoGiftPropsState((prev) => ({ ...prev, ...props }));
  };

  const resetSenderImageUrl = () => {
    setKlydoGiftPropsState((prev) => ({
      ...prev,
      senderImageUrl: defaultPhoto,
    }));
  };

  const [customKlydoId, setCustomKlydoId] = useState<string>();

  const prefetchController = useRef(new AbortController());
  const fetchAll = () => {
    if (initializing) {
      prefetchController.current = new AbortController();
      Promise.all(
        giftCategories?.map((category) => {
          return Promise.all(
            category.klydos.slice(0, 5).map((klydo) => {
              return axios.get(
                parseCloudinaryUrl({
                  url: klydo.convertedLoopUrl || klydo.loopUrl,
                  isStatic: false,
                  size: 150,
                }),
                {
                  signal: prefetchController.current.signal,
                },
              );
            }),
          );
        }),
      )
        .then(() => {
          setInitializing(false);
        })
        .catch((e) => {
          // got aborted
          if (e.name === "CanceledError") {
            return;
          } else {
            console.error(e);
          }
        });
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    if (selectedOccasion) {
      prefetchController.current.abort();
    }
    return () => {
      controller.abort();
    };
  }, [selectedOccasion, giftCategories]);

  useEffect(() => {
    getToken().then((token) => {
      if (token && ALLOWED_ROLES.includes(user.role)) setAdmin(true);
    });
  }, []);

  useEffect(() => {
    if (klydoGiftProps?.senderImageUrl) {
      axios.get(klydoGiftProps.senderImageUrl);
    }
  }, [klydoGiftProps]);

  useEffect(() => {
    if (!isLoading) {
      fetchAll();
    }
  }, [isLoading, giftCategories]);
  if (isLoading) {
    return SplashScreen();
  }
  const step = search.step;
  const activeStep = steps.findIndex((s) => s.id === step);
  const next = () => {
    setQueryParam("step", steps[activeStep + 1].id, true);
  };
  const back = () => {
    setQueryParam("step", steps[activeStep - 1].id, true);
  };
  return (
    <GiftContext.Provider
      value={{
        selectedOccasion,
        selectedKlydoGreet,
        klydoGiftProps,
        setSelectedOccasion,
        next,
        back,
        setSelectedKlydoGreet,
        setKlydoGiftProps,
        resetSenderImageUrl,
        giftCategories,
        admin,
        customKlydoId,
        setCustomKlydoId,
        loading,
        setLoading,
      }}
    >
      {children}
    </GiftContext.Provider>
  );
};
