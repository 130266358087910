import DSBox from "../system-design/DSBox";
import DSTextField from "../system-design/DSTextField";
import DSTypography from "../system-design/Typography/DSTypography";
import DSAvatar from "../system-design/DSAvatar";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isMobile } from "react-device-detect";
import ClearIcon from "@mui/icons-material/Clear";
import { KLYDO_BLACK } from "../../consts/colors";
import DSIconButton from "../system-design/DSIconButton";
import AddIcon from "@mui/icons-material/Add";
import useGiftForm from "./hooks/useGiftForm";
import GenerateGreetDialog from "./GenerateGreetDialog";
import DSButton from "../system-design/DSButtom";
import ErrorDialog from "./ErrorDialog";
import {
  ERROR_GREET_LIMIT_ALERT,
  ERROR_GREET_LIMIT_ALERT_REASON,
} from "../../consts/alerts";

const GiftForm = () => {
  const {
    theme,
    greeting,
    name,
    profileUrl,
    fileInputRef,
    klydoGiftProps,
    defaultPhoto,
    handleAvatarClick,
    handleGreetChange,
    handleNameChange,
    handleDateChange,
    handleRemoveProfile,
    handlePickProfile,
    linesLimit,
    handleClickGenerateGreet,
    generateGreetDialogOpen,
    handleCloseGenerateGreetDialog,
    handleSaveGenerateGreet,
    errorDialogOpen,
    handleCloseErrorDialog,
  } = useGiftForm();
  return (
    <DSBox
      width={"100%"}
      sx={{
        backgroundColor: theme.palette.background.paper,
        paddingTop: { xs: 2, md: 10 },
        paddingBottom: { xs: 10, md: 0 },
      }}
    >
      <DSBox
        gap={isMobile ? 0.5 : 5}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row-reverse" },
          alignItems: "center",
          textAlign: "left",
        }}
      >
        <DSBox
          sx={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
          }}
        >
          <DSTypography
            variant="h6"
            lineHeight={"18.96px"}
            fontSize={"16px"}
            fontFamily={"Gilroy-SemiBold"}
            color={"#C9C9C9"}
          >
            {profileUrl !== defaultPhoto ? "Change photo" : "Add your photo"}
          </DSTypography>
          <DSBox py={0.5} />
          <DSBox
            gap={0}
            position="relative"
            display="inline-block"
            sx={{ textAlign: "right" }}
          >
            {profileUrl !== defaultPhoto && (
              <DSIconButton
                onClick={handleRemoveProfile}
                style={{
                  backgroundColor: "transparent",
                  borderRadius: "50%",
                  position: "absolute",
                  left: 110,
                  top: -13,
                  color: "white",
                  cursor: "pointer",
                  fontFamily: "Gilroy-Bold",
                  fontSize: 20,
                }}
              >
                <ClearIcon />
              </DSIconButton>
            )}
            <DSAvatar
              onClick={handleAvatarClick}
              sx={{ width: 140, height: 140, cursor: "pointer" }}
            >
              <img
                src={profileUrl}
                alt="User Default"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: "3px solid white",
                  backgroundColor: KLYDO_BLACK,
                }}
              />
            </DSAvatar>
            {profileUrl !== defaultPhoto ? (
              <></>
            ) : (
              <DSBox
                onClick={handleAvatarClick}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  transition: "transform 0.3s ease",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                }}
              >
                <AddIcon sx={{ fontSize: 90, opacity: 0.75 }} />
              </DSBox>
            )}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".jpg,.jpeg,.png"
              onChange={handlePickProfile}
            />
          </DSBox>
        </DSBox>
        <DSBox
          width={isMobile ? "80%" : "350px"}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              *From
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Dad"
              value={name}
              fullWidth
              onChange={(e) => handleNameChange(e.target.value)}
              required
              slotProps={{
                input: {
                  inputProps: { maxLength: 14 },
                },
              }}
              sx={{
                backgroundColor: "#212121",
                ".MuiInputBase-input": {
                  fontSize: "15px",
                },
              }}
            />
          </DSBox>
          <DSBox py={1} />
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Write your greeting
            </DSTypography>
            <DSBox py={0.5} />
            <DSTextField
              placeholder="Dear Ron..."
              value={greeting}
              onChange={(e) => handleGreetChange(e.target.value)}
              multiline
              fullWidth
              rows={5}
              maxRows={8}
              slotProps={{
                input: {
                  style: {
                    padding: "20px",
                  },
                  onKeyDown: linesLimit,
                },
              }}
              sx={{
                backgroundColor: "#212121",
                ".MuiInputBase-input": {
                  overflowWrap: "break-word",
                  fontSize: "15px",
                  maxWidth: "300px",
                  display: "block",
                },
              }}
            />
            <DSBox py={0.5} />
            <DSBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <DSTypography
                variant="h6"
                lineHeight={"18.96px"}
                fontSize={"16px"}
                color={"#C9C9C9"}
                fontFamily={"Gilroy-SemiBold"}
              >
                {greeting.length}/160
              </DSTypography>
              <DSButton
                variant="text"
                onClick={handleClickGenerateGreet}
                sx={{
                  textDecoration: "underline",
                  fontFamily: "Gilroy-Medium",
                  fontSize: "14px",
                  color: "#C9C9C9",
                  textTransform: "none",
                  margin: 0,
                  padding: 0,
                }}
              >
                Generate Greet with AI
              </DSButton>
            </DSBox>
          </DSBox>
          <DSBox py={1} />
          <DSBox>
            <DSTypography
              variant="h6"
              lineHeight={"18.96px"}
              fontSize={"16px"}
              color={"#C9C9C9"}
              fontFamily={"Gilroy-SemiBold"}
            >
              Date of occasion
            </DSTypography>
            <DSBox py={0.5} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={!klydoGiftProps.date ? "MM/DD/YYYY" : ""}
                value={
                  klydoGiftProps?.date
                    ? dayjs(new Date(klydoGiftProps?.date))
                    : null
                }
                onChange={(newDate) => handleDateChange(newDate)}
                sx={{
                  backgroundColor: "#212121",
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </DSBox>
        </DSBox>
      </DSBox>
      <GenerateGreetDialog
        open={generateGreetDialogOpen}
        onClose={handleCloseGenerateGreetDialog}
        onSave={handleSaveGenerateGreet}
      />
      <ErrorDialog
        errorTitle={ERROR_GREET_LIMIT_ALERT}
        errorReason={ERROR_GREET_LIMIT_ALERT_REASON}
        isErrorDialogOpen={errorDialogOpen}
        handleClose={handleCloseErrorDialog}
      />
    </DSBox>
  );
};

export default GiftForm;
