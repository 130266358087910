import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { KLYDO_BLACK, KLYDO_YELLOW } from "./consts/colors";

const container = document.getElementById("root");
const root = createRoot(container);
const darkTheme = createTheme({
  typography: {
    fontFamily: [
      "Gilroy-bold, Gilroy-SemiBold, Gilroy-Medium, Gilroy-Regular, Gilroy-Light",
    ].join(","),
    button: {
      textTransform: "capitalize",
      fontFamily: "Gilroy-SemiBold",
      fontWeight: 500,
    },
    h3: {
      fontFamily: "Gilroy-Bold",
      lineHeight: "56px",
      fontSize: "48px",
      fontWeight: 400,
    },
    h5: {
      fontFamily: "Gilroy-Medium",
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Gilroy-SemiBold",
      fontSize: "19px",
      lineHeight: "24px",
    },
    subtitle1: {
      fontFamily: "Gilroy-Medium",
      fontSize: "16px",
      lineHeight: "28px",
      fontWeight: 400,
      color: "rgba(255, 255, 255, 0.7)",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: KLYDO_YELLOW,
      contrastText: KLYDO_BLACK,
    },
    secondary: {
      main: "#212121",
      contrastText: "#626262",
    },
    background: {
      default: KLYDO_BLACK,
      paper: KLYDO_BLACK,
    },
  },
});

root.render(
  <ThemeProvider theme={darkTheme}>
    <App />
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
