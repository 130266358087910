import { useNavigate } from "react-router-dom";
import { useHistory } from "../../../utils";
import { useGiftContext } from "../Context/GiftContextProvider";
import useOrderGift from "./useOrderGift";
import useOrderQuery from "./useOrderQuery";
import { usePrefetchProductVariants } from "./usePrefetchProductVariants";
import { useEffect, useState } from "react";
import { PopulatedClockGift } from "../../../api/gift";
import { set } from "lodash";

const useGiftHomePage = () => {
  const { search } = useHistory();
  const prefetchProductVariants = usePrefetchProductVariants();
  const { setKlydoGiftProps, klydoGiftProps, setSelectedKlydoGreet } =
    useGiftContext();
  const { data: orderInfo, isLoading: isLoadingOrderInfo } = useOrderQuery(
    search.orderId as string,
  );
  const { data: gift, isLoading: isLoadingGift } = useOrderGift(
    search.orderId as string,
  );

  const [enterClockDialogOpen, setEnterClockDialogOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const orderId = search?.orderId;
    const clockId = search?.clockId;
    let timeZone: string;
    try {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch {}
    setKlydoGiftProps({
      ...klydoGiftProps,
      orderId: orderId as string,
      clockId: clockId as string,
      timeZone,
    });
  }, []);

  const isShopifyRedirect = klydoGiftProps?.orderId === "shopify-redirect";

  useEffect(() => {
    const fetchProductVariants = async () => {
      if (isShopifyRedirect) {
        await prefetchProductVariants;
      }
    };
    fetchProductVariants();
  }, [isShopifyRedirect]);

  useEffect(() => {
    if (isShopifyRedirect) {
      const walnutImage = new Image();
      const oakImage = new Image();
      walnutImage.src = require("../assets/clock_walnut.png");
      oakImage.src = require("../assets/clock_oak.png");
    }
  }, [isShopifyRedirect]);

  const handleCreateGreet = () => {
    if (!klydoGiftProps?.orderId && !klydoGiftProps?.clockId) {
      setEnterClockDialogOpen(true);
      return;
    }
    if (klydoGiftProps?.orderId === "shopify-redirect") {
      navigate(`/gift/wizard?step=occasion&orderId=shopify-redirect`);
    } else {
      navigate(`/gift/wizard?step=occasion`);
    }
  };

  const handleCloseEnterClockDialog = () => {
    setEnterClockDialogOpen(false);
  };

  const prsonalInfo = orderInfo?.data?.first_name
    ? `${orderInfo?.data?.first_name} ${orderInfo?.data?.last_name}`
    : "";

  if (isLoadingOrderInfo || isLoadingGift) {
    return { isLoading: true };
  }

  if (gift) {
    setKlydoGiftProps(gift);
    setSelectedKlydoGreet(gift.klydo);
    navigate(`/gift/done`);
  }

  const handleClockConfirm = async (clockGift: PopulatedClockGift) => {
    if (clockGift.gift) {
      setKlydoGiftProps(clockGift.gift);
      setSelectedKlydoGreet(clockGift.gift.klydo);
      navigate(`/gift/done`);
      return;
    }
    const { clockId } = clockGift;
    setKlydoGiftProps({
      ...klydoGiftProps,
      clockId,
    });
    navigate(`/gift/wizard?step=occasion`);
    setEnterClockDialogOpen(false);
  };

  return {
    isLoading: false,
    handleCreateGreet,
    prsonalInfo,
    navigate,
    isShopifyRedirect,
    enterClockDialogOpen,
    handleCloseEnterClockDialog,
    handleClockConfirm,
  };
};

export default useGiftHomePage;
