import { ChangeEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, InputOnChangeData, Message, Segment } from "semantic-ui-react";
import {
  GoogleSignInButton,
  LoginContainer,
  LoginContent,
} from "./loginStyles";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorHeader, setErrorHeader] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const handleEmailInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setEmail(value);
  };

  const handlePasswordInputChange = (
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData,
  ): void => {
    const { value } = data;
    setPassword(value);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => navigate("/"))
      .catch((e) => {
        console.log(e);

        setError(true);
        setErrorHeader("Error");
        setErrorMessage("login failed");
      });
  }

  return (
    <LoginContainer>
      <LoginContent>
        <div>
          <Segment
            inverted
            style={{
              backgroundColor: "transparent",
              display: "grid",
              placeItems: "center",
              marginTop: "3em",
            }}
          >
            <GoogleSignInButton
              onClick={() => {
                firebase
                  .auth()
                  .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                  .then((e) => {
                    navigate("/");
                  })
                  .catch((e) => {
                    setError(true);
                    setErrorHeader("Something went wrong");
                    setErrorMessage("Please try again later");
                  });
              }}
              content="Sign in with Google"
              size="large"
              color="google plus"
              icon="google"
              labelPosition="left"
              className="fluid"
            />
          </Segment>
          <Form size="large"></Form>
          <div
            style={{
              margin: "0.5em 0",
              display: "grid",
              placeItems: "center",
              fontSize: "small",
              color: "#fff",
            }}
          >
            - or use your Klydo account -
          </div>

          <Form size="large" onSubmit={handleSubmit}>
            <Segment inverted style={{ backgroundColor: "transparent" }}>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="E-mail address"
                onChange={handleEmailInputChange}
                error={emailError}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={handlePasswordInputChange}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5em 0 1.5em",
                }}
              >
                <Link
                  to="/register"
                  style={{ color: "white", margin: "0 0.5em" }}
                >
                  Don't have an account?
                </Link>
                <Link
                  to="/forgot-password"
                  style={{ color: "white", margin: "0 0.5em" }}
                >
                  Forgot Password?
                </Link>
              </div>
              <Form.Button
                color="instagram"
                type="submit"
                disabled={!email || !password}
                fluid
                size="large"
              >
                Login
              </Form.Button>
            </Segment>
          </Form>
          {error && (
            <Message
              error
              icon="warning circle"
              header={errorHeader}
              content={errorMessage}
            />
          )}
        </div>
      </LoginContent>
    </LoginContainer>
  );
};

export default Login;
