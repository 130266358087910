import axios from "axios";
import giftClient from "../../../api/gift";
import useEditorQuery from "../../../hooks/useEditorQuery";

const useGiftCategories = () => {
  return useEditorQuery({
    queryKey: ["gift-categories"],
    queryFn: async () => {
      const categories = await giftClient.listCategories();
      await Promise.all(
        categories.map((category) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
              resolve(img);
            };
            img.onerror = img.onabort = function () {
              reject(category.categoryImageUrl);
            };
            img.src = category.categoryImageUrl;
          });
        }),
      );
      return categories;
    },
    refetchOnWindowFocus: false,
  });
};

export default useGiftCategories;
