import { isIOS, isMobile } from "react-device-detect";
import DSAppBar from "../system-design/DSAppBar";
import DSBox from "../system-design/DSBox";
import DSPaper from "../system-design/DSPaper";
import ProductPage from "./ProductPage";
import { useTheme } from "@mui/material";
import logo from "./assets/klydoclock-yellow.png";

const CustomizeLaterProductPage = () => {
  const theme = useTheme();
  const handleBuyNow = async (productVariantId: string, quantity: number) => {
    const checkoutUrl = `https://www.klydoclock.com/cart/add?id=${productVariantId}&quantity=${quantity}&properties[Greet]=KlydoGreet`;
    if (window.self !== window.top) {
      window.parent.location.href = checkoutUrl;
    } else {
      window.location.href = checkoutUrl;
    }
  };
  const klydo = {
    name: "default",
    loopUrl:
      "https://res.cloudinary.com/klydoclock/video/upload/v1735813978/gifs/n6ve8rlmtv4spf6krryt.mp4",
    theme: {
      handsColor: "#000000",
      backgroundColor: "#fedc4f",
      pendulumColor: "#000000",
      pendulumRodColor: "#000000",
    },
  };

  const gift = {
    senderName: "Your Name",
  };

  return (
    <DSPaper
      sx={{
        overflow: "hidden",
        minHeight: { xs: isIOS && isMobile ? 0 : "100vh", md: "100vh" },
      }}
    >
      <DSAppBar
        onClick={() => {
          window.location.href = "https://www.klydoclock.com/";
        }}
        position="fixed"
        color="transparent"
        sx={{
          cursor: "pointer",
          backgroundColor: theme.palette.background.paper,
          paddingY: { xs: 2, md: 4 },
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "none",
        }}
      >
        <img width={isMobile ? "120" : "150"} src={logo} />
      </DSAppBar>
      <DSBox
        id="gift-wizard-step"
        sx={{
          overflow: "auto",
          backgroundColor: theme.palette.background.paper,
          paddingTop: { xs: 8, md: 13 },
          height: "100vh",
          paddingBottom: { xs: 10, md: 20 },
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "start",
          overflowX: "hidden",
        }}
      >
        <ProductPage
          handleBuyNow={handleBuyNow}
          isShopifyRedirect={true}
          klydo={klydo}
          klydoGiftProps={gift}
        />
      </DSBox>
    </DSPaper>
  );
};
export default CustomizeLaterProductPage;
