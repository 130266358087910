import { Component } from "react";
import bg from "./darkSplashScreen.png";
import logo from "./logo-yellow.png";
import logoDark from "./logo-dark.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import DSCircularProgress from "../themes-views/system-design/DSCircularProgress";
export const ALLOWED_ROLES = ["editor", "admin", "viewer"];

export function SplashScreen() {
  return (
    <div
      id="fuse-splash-screen"
      style={{ background: "#0F0F22", backgroundImage: `url(${bg})` }}
    >
      <div className="center">
        <div
          style={{
            width: 128,
            margin: "0 auto",
          }}
        >
          <img width="128" src={logo} alt="..." />
        </div>
        <div
          style={{
            paddingTop: 20,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DSCircularProgress />
        </div>
      </div>
    </div>
  );
}

const ErrorScreen = (err: any) => (
  <div className="login">
    <div className="logo" style={{ marginLeft: 40 }}>
      <img width="128" src={logoDark} alt="..." />
    </div>
    <h5 className="mx-auto mt-3" style={{ width: 208 }}>
      {err ? "Oops... somthing went wrong" : err}
    </h5>
    <a href="/login" style={{ width: 208, marginLeft: 20 }}>
      Return to Login
    </a>
  </div>
);

function withSplashScreen(WrappedComponent: any) {
  return class extends Component<
    {},
    { loading: boolean; user: any; error: boolean | string }
  > {
    constructor(props: any) {
      super(props);
      this.state = {
        loading: true,
        user: false,
        error: false,
      };
    }

    async componentDidMount() {
      await this.firebaseCheck();
      this.setState({
        loading: false,
      });
    }

    firebaseCheck = () =>
      new Promise<void>((resolve) => {
        firebase.auth().onAuthStateChanged((authUser: firebase.User) => {
          if (authUser) {
            authUser.getIdTokenResult().then((e) => {
              this.setState({
                user: { ...authUser.toJSON(), role: e.claims.role },
              });
              resolve();
            });
          } else {
            this.setState({ user: undefined });
            resolve();
          }
        });
      });

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return SplashScreen();

      if (this.state.error)
        return ErrorScreen(
          typeof this.state.error == "string" ? this.state.error : "",
        );

      // otherwise, show the desired route
      return <WrappedComponent user={this.state.user} />;
    }
  };
}

export default withSplashScreen;
